import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONPretty from 'react-json-pretty';
//More info on styling the json box can be found here https://www.npmjs.com/package/react-json-pretty
import 'react-json-pretty/themes/monikai.css';

import { formatDate } from '../utility/utility';

import './OrderDetailModal.css';

function OrderDetailModal({ open, onClose, rowData }) {
    const getComplianceDescription = (complianceResponse) => {
        if (complianceResponse.salesOrder?.complianceStatus === "NotCompliant") {
            const rules = complianceResponse.salesOrder.shipments?.[0]?.rules || [];
            return (
                <dl className='compliance-description'>
                    {rules.filter(rule => !['Compliant', 'Bypassed'].includes(rule.complianceStatus))
                        .map((rule, index) => (
                        <React.Fragment key={index}>
                            <dt className='compliance-description-heading'>{rule.complianceDescription}</dt>
                            <dd className='compliance-description-content'>{rule.ruleDescription}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            );
        }
        return null;
    };

    const getErrorDescription = (errorResponse) => {
        if (Array.isArray(errorResponse.errors)) {
            const filteredErrors = errorResponse.errors.filter(error =>
                !error.message.includes("SalesOrder has been committed")
            );
            if (filteredErrors.length === 0) return null;
            return (
                <dl className='compliance-description'>
                    {filteredErrors.map((error, index) => (
                        <React.Fragment key={index}>
                            <dt className='compliance-description-heading'>
                                Error {error?.code ? `(${error?.code})` : ''}
                            </dt>
                            <dd>{error.message}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            );
        } else if (errorResponse.message && !errorResponse.message.includes("SalesOrder has been committed")) {
            return (
                <dl className='compliance-description'>
                    <dt className='compliance-description-heading'>
                        Error {errorResponse?.code ? `(${errorResponse?.code})` : ''}
                    </dt>
                    <dd className='compliance-description-content'>{errorResponse.message}</dd>
                </dl>
            );
        }
        return null;
    };
    
    const getDescription = (rowData) => {
        if (!rowData) return null;
        let description = null;
        if (rowData.compliance_response) {
            try {
                const complianceResponse = JSON.parse(rowData.compliance_response);
                description = getComplianceDescription(complianceResponse);
            } catch (error) {
                console.error("Error parsing compliance_response:", error);
            }
        }

        if (!description && rowData.error) {
            try {
                const errorResponse = JSON.parse(rowData.error);
                description = getErrorDescription(errorResponse);
            } catch (error) {
                console.error("Error parsing error response:", error);
            }
        }

        return description || '';
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Order #{rowData && rowData?.order_name}</DialogTitle>
            <DialogContent>
                {rowData && (
                    <div>
                        <Typography variant="subtitle1">Basic Information:</Typography>
                        <p>Compliance ID: {rowData.id}</p>
                        <p>Created At: {formatDate(rowData.created_at)}</p>
                        <p>Updated At: {formatDate(rowData.updated_at)}</p>
                        <p>Order ID: {rowData?.order_name}</p>
                        <p>Status: {rowData.order_status}</p>
                        <p>Compliance Status: {rowData.compliance_status}</p>
                        <p>{getDescription(rowData) && 'Compliance Description:'}</p>
                        {getDescription(rowData)}
                        <p>Email: {rowData.email}</p>
                        <p>First Name: {rowData.order_firstname}</p>
                        <p>Last Name: {rowData.order_lastname}</p>

                        <Typography variant="subtitle1">Request Information:</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Compliance Request</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.compliance_request} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Compliance Response</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.compliance_response} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Commit Request</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.commit_request} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Commit Response</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.commit_response} />
                            </AccordionDetails>
                        </Accordion>

                        <Typography variant="subtitle1">Errors</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Error</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.error} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default OrderDetailModal;